import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Appartment1_Img1 from '../../assets/images/listing/listing2/1.webp';
import Appartment1_Img2 from '../../assets/images/listing/listing2/2.webp';
import Appartment1_Img3 from '../../assets/images/listing/listing2/3.webp';
import Appartment1_Img4 from '../../assets/images/listing/listing2/4.webp';
import Appartment1_Img5 from '../../assets/images/listing/listing2/5.webp';
import Appartment1_Img6 from '../../assets/images/listing/listing2/6.webp';
import Appartment1_Img7 from '../../assets/images/listing/listing2/7.webp';
import Appartment1_Img8 from '../../assets/images/listing/listing2/8.webp';
import Appartment1_Img9 from '../../assets/images/listing/listing2/9.webp';
import Appartment1_Img10 from '../../assets/images/listing/listing2/10.webp';
import Appartment1_Img11 from '../../assets/images/listing/listing2/11.webp';
import Appartment1_Img12 from '../../assets/images/listing/listing2/12.webp';
import Appartment1_Img13 from '../../assets/images/listing/listing2/13.webp';

import Appartment2_Img1 from '../../assets/images/listing/listing3/1.webp';
import Appartment2_Img2 from '../../assets/images/listing/listing3/2.webp';
import Appartment2_Img3 from '../../assets/images/listing/listing3/3.webp';
import Appartment2_Img4 from '../../assets/images/listing/listing3/4.webp';
import Appartment2_Img5 from '../../assets/images/listing/listing3/5.webp';
import Appartment2_Img6 from '../../assets/images/listing/listing3/6.webp';
import Appartment2_Img7 from '../../assets/images/listing/listing3/7.webp';
import Appartment2_Img8 from '../../assets/images/listing/listing3/8.webp';
import Appartment2_Img9 from '../../assets/images/listing/listing3/9.webp';
import Appartment2_Img10 from '../../assets/images/listing/listing3/10.webp';
import Appartment2_Img11 from '../../assets/images/listing/listing3/11.webp';
import Appartment2_Img12 from '../../assets/images/listing/listing3/12.webp';
import Appartment2_Img13 from '../../assets/images/listing/listing3/13.webp';
import Appartment2_Img14 from '../../assets/images/listing/listing3/14.webp';
import Appartment2_Img15 from '../../assets/images/listing/listing3/15.webp';
import Appartment2_Img16 from '../../assets/images/listing/listing3/16.webp';

import Appartment3_Img1 from '../../assets/images/listing/listing18/1.webp';
import Appartment3_Img2 from '../../assets/images/listing/listing18/2.webp';
import Appartment3_Img3 from '../../assets/images/listing/listing18/3.webp';
import Appartment3_Img4 from '../../assets/images/listing/listing18/4.webp';
import Appartment3_Img5 from '../../assets/images/listing/listing18/5.webp';
import Appartment3_Img6 from '../../assets/images/listing/listing18/6.webp';
import Appartment3_Img7 from '../../assets/images/listing/listing18/7.webp';
import Appartment3_Img8 from '../../assets/images/listing/listing18/8.webp';
import Appartment3_Img9 from '../../assets/images/listing/listing18/9.webp';
import Appartment3_Img10 from '../../assets/images/listing/listing18/10.webp';

import SingleListing from './SingleListing';

const Villas = () => {

  return (
    <>
      <SingleListing
      Img1={Appartment1_Img1}
      Img2={Appartment1_Img2}
      Img3={Appartment1_Img3}
      Img4={Appartment1_Img4}
      Img5={Appartment1_Img5}
      Img6={Appartment1_Img6}
      Img7={Appartment1_Img7}
      Img8={Appartment1_Img8}
      Img9={Appartment1_Img9}
      Img10={Appartment1_Img10}
      Img11={Appartment1_Img11}
      Img12={Appartment1_Img12}
      Img13={Appartment1_Img13}
      Link="/properties-for-sale/sharjah/townhouse-for-sale-in-saro-masaar"
      Title={
        <>
        Townhouse in Saro, Masaar
        </>
      }
      Address="Sharjah"
      Category="Townhouse"
      Beds="4"
      Baths="4"
      SqFt="1,916 sqft / 178 sqm"
      Price="2,802,000"
      />       
      
      <SingleListing
      Img1={Appartment2_Img1}
      Img2={Appartment2_Img2}
      Img3={Appartment2_Img3}
      Img4={Appartment2_Img4}
      Img5={Appartment2_Img5}
      Img6={Appartment2_Img6}
      Img7={Appartment2_Img7}
      Img8={Appartment2_Img8}
      Img9={Appartment2_Img9}
      Img10={Appartment2_Img10}
      Img11={Appartment2_Img11}
      Img12={Appartment2_Img12}
      Img13={Appartment2_Img13}
      Img14={Appartment2_Img14}
      Img15={Appartment2_Img15}
      Img16={Appartment2_Img16}
      Link="/properties-for-sale/dubai/townhouse-for-sale-in-casablanca-boutique-villas-pacifica"
      Title="Townhouse in Casablanca Boutique Villas, Pacifica"
      Address="Dubai"
      Category="Townhouse"
      Beds="3"
      Baths="4"
      SqFt="1,881 sqft / 175 sqm"
      Price="1,599,995"
      />       
      
      <SingleListing
      Img1={Appartment3_Img1}
      Img2={Appartment3_Img2}
      Img3={Appartment3_Img3}
      Img4={Appartment3_Img4}
      Img5={Appartment3_Img5}
      Img6={Appartment3_Img6}
      Img7={Appartment3_Img7}
      Img8={Appartment3_Img8}
      Img9={Appartment3_Img9}
      Img10={Appartment3_Img10}
      Link="/properties-for-sale/dubai/townhouse-for-sale-in-camelia-damac-hills-2"
      Title="Townhouse In Camelia, Damac Hills 2"
      Address="Dubai"
      Category="Townhouse"
      Beds="3"
      Baths="4"
      SqFt="1,208 sqft / 112 sqm"
      Price="1,340,000"
      />                
      
    </>
  );
}

export default Villas;
