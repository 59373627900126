import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ProfileDesktop from '../components/faraaz/ProfileDesktop';
import ProfileMobile from '../components/faraaz/ProfileMobile';
import Bhwna from '../assets/images/Bhwna.png';

const ProfileBhwnaManvani = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>

    <Helmet>
    <meta name="robots" content="noindex"/>
    <meta charSet="utf-8" />
    <title> Bhwna Manvani - 800 Homes </title>
    </Helmet>

    <section id="website-responsive" className="d-none d-md-block">
      <ProfileDesktop
      Image={Bhwna}
      Title="Bhwna Manvani"
      Designation="Sales Director"
      PhoneNumberLink="+971585676887"
      PhoneNumber="+971 58 567 6887"
      WhatsappLink="971585676887"
      Whatsapp="+971 58 567 6887"
      Email="bhwna@800homes.ae"
      />
    </section>

    <section id="website-responsive" className="d-block d-md-none">
      <ProfileMobile
      Image={Bhwna}
      Title="Bhwna Manvani"
      Designation="Sales Director"
      PhoneNumberLink="+971585676887"
      PhoneNumber="+971 58 567 6887"
      WhatsappLink="971585676887"
      Whatsapp="+971 58 567 6887"
      Email="bhwna@800homes.ae" 
      />
    </section>

    </>
  );
}

export default ProfileBhwnaManvani;
