import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Img1 from '../../assets/images/listing/listing16/1.webp';
import Img2 from '../../assets/images/listing/listing16/2.webp';
import Img3 from '../../assets/images/listing/listing16/3.webp';
import Img4 from '../../assets/images/listing/listing16/4.webp';
import Img5 from '../../assets/images/listing/listing16/5.webp';
import Img6 from '../../assets/images/listing/listing16/6.webp';
import Img7 from '../../assets/images/listing/listing16/7.webp';
import Img8 from '../../assets/images/listing/listing16/8.webp';
import Img9 from '../../assets/images/listing/listing16/9.webp';
import Img10 from '../../assets/images/listing/listing16/10.webp';
import Img11 from '../../assets/images/listing/listing16/11.webp';
import Img12 from '../../assets/images/listing/listing16/12.webp';
import Img13 from '../../assets/images/listing/listing16/13.webp';
import Img14 from '../../assets/images/listing/listing16/14.webp';
import Img15 from '../../assets/images/listing/listing16/15.webp';
import Img16 from '../../assets/images/listing/listing16/16.webp';
import Img17 from '../../assets/images/listing/listing16/17.webp';
import Img18 from '../../assets/images/listing/listing16/18.webp';
import Img19 from '../../assets/images/listing/listing16/19.webp';
import Img20 from '../../assets/images/listing/listing16/20.webp';
import Img21 from '../../assets/images/listing/listing16/21.webp';
import Img22 from '../../assets/images/listing/listing16/22.webp';
import Img23 from '../../assets/images/listing/listing16/23.webp';

import Header from "../../components/layout/Header";

import Footer from "../../components/layout/Footer";
import DetailsOfListing from "../../components/listing/individual/DetailsOfListing";
import InquiryOfListing from "../../components/listing/individual/InquiryOfListing";
import ShareSocialMediaIcons from "../../components/listing/individual/ShareSocialMediaIcons";
import { Link } from "react-router-dom";
import FeaturesAndAmenities from "../../components/listing/individual/FeaturesAndAmenities";
import ListingDetailsSection from "../../components/listing/individual/ListingDetailsSection";

const Listing15 = (props) => {
  
  const images = [
    {
      original: Img1,
      thumbnail: Img1,
    },
    {
      original: Img2,
      thumbnail: Img2,      
    },
    {
      original: Img3,
      thumbnail: Img3,      
    },
    {
      original: Img4,
      thumbnail: Img4,      
    },
    {
      original: Img5,
      thumbnail: Img5,      
    },
    {
      original: Img6,
      thumbnail: Img6,      
    },
    {
      original: Img7,
      thumbnail: Img7,      
    },
    {
      original: Img8,
      thumbnail: Img8,      
    },
    {
      original: Img9,
      thumbnail: Img9,      
    },
    {
      original: Img10,
      thumbnail: Img10,      
    },
    {
      original: Img11,
      thumbnail: Img11,      
    },
    {
      original: Img12,
      thumbnail: Img12,      
    },
    {
      original: Img13,
      thumbnail: Img13,      
    },
    {
      original: Img14,
      thumbnail: Img14,      
    },
    {
      original: Img15,
      thumbnail: Img15,      
    },
    {
      original: Img16,
      thumbnail: Img16,      
    },
    {
      original: Img17,
      thumbnail: Img17,      
    },
    {
      original: Img18,
      thumbnail: Img18,      
    },
    {
      original: Img19,
      thumbnail: Img19,      
    },
    {
      original: Img20,
      thumbnail: Img20,      
    },
    {
      original: Img21,
      thumbnail: Img21,      
    },
    {
      original: Img22,
      thumbnail: Img22,      
    },
    {
      original: Img23,
      thumbnail: Img23,      
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <Helmet>
      <title>
      Apartment For Sale In Six Senses Residences, Dubai Marina
      </title>
      <link rel="canonical" href="/properties-for-sale/dubai/apartment-for-sale-in-six-senses-residences-dubai-marina"/>
    </Helmet>
    <Header/>
    <section id="website-responsive" className="container-fluid py-5 border-top border-1 border-white">
      <div className="row justify-content-evenly">
        <div className="col-11 col-md-7 px-0">
        <div className="">
          <div className="" data-aos="fade-up">
          <ImageGallery className="transition" items={images} />
          </div>
          <DetailsOfListing
          Title="APARTMENT FOR SALE IN SIX SENSES RESIDENCES, DUBAI MARINA"
          Title2="Breath Taking Views | 2BR Six Senses-Select Group"
          Price="7,075,000"
          Location="Dubai"
          Category="Apartment"
          Beds="2"
          Baths="3"
          Area="1,997 sqft / 186 sqm"
          FeaturesAndAmenities={<>
            <FeaturesAndAmenities Title="Central A/C" />
            <FeaturesAndAmenities Title="Balcony" />
            <FeaturesAndAmenities Title="Private Gym" />
            <FeaturesAndAmenities Title="Shared Pool" />
            <FeaturesAndAmenities Title="Security" />
            <FeaturesAndAmenities Title="Built in Wardrobes" />
            <FeaturesAndAmenities Title="Walk-in Closet" />
            <FeaturesAndAmenities Title="Shared Gym" />
            <FeaturesAndAmenities Title="Lobby in Building" />
            <FeaturesAndAmenities Title="Children's Pool" />
            <FeaturesAndAmenities Title="Children's Play Area" />
            <FeaturesAndAmenities Title="Barbecue Area" />  
            </>}
          Description={<>
          Introducing Six Senses Dubai Marina Residences—the world’s tallest residential tower, soaring to an impressive 122 floors. Experience unmatched luxury with 251 exquisite residences, ranging from stylish 2-bedroom units to opulent 5-bedroom triplexes.
          <div className="py-2" />
          A Wellness Haven: Dive into a world of wellness with over 61,250 sq. ft. of amenities, including a breathtaking pool on the 109th floor, designed to enhance your well-being and promote relaxation. Each residence is thoughtfully curated with Sleep With Six Senses bedroom amenities and features inspired by biophilia and classical feng shui, ensuring a harmonious living experience.
          <div className="py-2" />
          Breathtaking Views: Revel in panoramic vistas overlooking the Arabian Sea and Dubai's iconic landmarks, creating an enchanting backdrop for your daily life. Indulge in exclusive penthouses and duplexes that offer unparalleled space and privacy.
          <div className="py-2" />
          Unrivaled Amenities: Residents enjoy a comprehensive range of amenities across four levels, crafted to foster wellness, longevity, and community. Located in the vibrant Dubai Marina district, you’ll find yourself at the epicenter of cosmopolitan energy, surrounded by stunning waterfront views and a plethora of world-class amenities.
          <div className="py-2" />
          Prime Location: <br />
          5 Minutes: Dubai Marina, Marina Mall, Dubai Harbour Beachfront, Emirates Golf Club <br />
          10 Minutes: JBR The Beach, Bluewaters, The Palm Jumeirah, Mall of the Emirates <br />
          20 Minutes: Palm Jebel Ali, Expo City <br />
          30 Minutes: Dubai International Airport, Al Maktoum International Airport
          <div className="py-2" />
          Elevate your lifestyle at Six Senses Dubai Marina Residences, where luxury meets wellness in an iconic setting.

          </>}
          ListingDetailsSection={<>
          <ListingDetailsSection Title1="Property Type" Title2="Apartment" />          
          <ListingDetailsSection Title1="Property Size" Title2="1,997 sqft / 186 sqm" />          
          <ListingDetailsSection Title1="Bedrooms" Title2="2" />          
          <ListingDetailsSection Title1="Bathrooms" Title2="3" />     
          </>}
          />
      </div>
        </div>
        <div className="col-11 col-md-3">
          <div className="border-white border-1 border p-4 mb-4 sticky-top">
          <InquiryOfListing
          Title="Apartment For Sale In Six Senses Residences, Dubai Marina"
          />
          <ShareSocialMediaIcons
          url_Email="https://800homes.eacademe.org/apartment-for-sale-dubai-dubai-marina-six-senses-residences"
          url_Whatsapp="https://800homes.eacademe.org/apartment-for-sale-dubai-dubai-marina-six-senses-residences"
          url_Facebook="https://800homes.eacademe.org/apartment-for-sale-dubai-dubai-marina-six-senses-residences"
          url_Linkedin="https://800homes.eacademe.org/apartment-for-sale-dubai-dubai-marina-six-senses-residences"
          />
          </div>
        </div>        
      </div>
    </section>
    <Footer/>
    </>
  )
}

export default Listing15 