import React, {useEffect, useState} from 'react';
import { Helmet } from 'react-helmet';
import HomeBanner from '../components/home/HomeBanner';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import { Link } from 'react-router-dom';
import Img1 from '../assets/images/sell-property/Img1.webp';
import Img2 from '../assets/images/sell-property/Img2.webp';
import Img3 from '../assets/images/sell-property/Img3.webp';
import Img4 from '../assets/images/sell-property/Img4.webp';
import { CheckLg } from 'react-bootstrap-icons';
import Icon4 from '../assets/images/why-800-homes/house.png';
import BannerImg from '../assets/images/banners/Banner1.webp';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import InquiryForm from '../components/InquiryForm';
import SellInquiryForm from '../components/SellInquiryForm';

const SellProperty = () => {
  const [counterOn, setCounterOn] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
            <>
        <Helmet>
        <meta charSet="utf-8" />
        <title> Sell Property in Dubai | 800 Homes - Professional Dubai Brokers </title>
        <meta name="description" content="Ready to sell your property in Dubai? 800 Homes offers personalized services to help you sell quickly and profitably. Let our expert brokers guide you every step of the way." />
        
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Sell Property in Dubai | 800 Homes - Professional Dubai Brokers" />
        <meta property="og:description" content="Ready to sell your property in Dubai? 800 Homes offers personalized services to help you sell quickly and profitably. Let our expert brokers guide you every step of the way." />
        <meta property="og:url" content="https://800homes.ae/" />
        <meta property="og:site_name" content="800homes" />
        <meta property="og:image" content="https://800homes.ae/og.webp" />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
        <meta property="og:image:secure_url" content="https://800homes.ae/og.webp" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content="Ready to sell your property in Dubai? 800 Homes offers personalized services to help you sell quickly and profitably. Let our expert brokers guide you every step of the way." />
        <meta name="twitter:title" content="Sell Property in Dubai | 800 Homes - Professional Dubai Brokers" />
        <meta name="twitter:image" content="https://800homes.ae/og.webp"/>
        <link rel="canonical" href="https://800homes.ae/sell-property"/>
        </Helmet>
    <Header/>
    <section id='website-responsive' className="container-fluid px-0">
    <section id='website-responsive' className="container-fluid common-banner shadow-800homes" style={{backgroundImage:`url(${BannerImg})`}}>
      <div className="row justify-content-center align-items-center">
        <div className="col-11 col-lg-10">
          <h1 className="lh-sm fw-500 banner-title-sell-property text-uppercase text-white text-center" data-aos="fade-up">
          Sell Your Property with <br className='d-none d-md-block' /> Maximum Profit
          </h1>
          <div>
          </div>
        </div>
      </div>
    </section>

    <section className="py-md-5 py-4" />

    <section className="container-fluid">
      <div className="row justify-content-center gy-4" >
      <div className="col-11 col-md-3 border-ss-primary p-4 text-white" data-aos="fade-up">
          <div className="fs-ss-24 pb-3 fw-300 text-ss-primary">
            Increase Visibility for Faster Sales    
          </div>
          <div className="fs-ss-16 fw-300">
          Increased visibility is a significant advantage of online listings. By showcasing your property on popular platforms, you'll reach a vast audience of potential buyers, renters, and investors locally and globally. This increased exposure can lead to more inquiries, viewings, and a faster sale or rental. With millions of users searching for properties online, you can't afford to miss out on this opportunity. 
          </div>
        </div>

        <div className="col-11 col-md-3 border-ss-primary p-4 text-white mx-4" data-aos="fade-up">
          <div className="fs-ss-24 pb-3 fw-300 text-ss-primary">
            Targeted Marketing for the Right Buyers  
          </div>
          <div className="fs-ss-16 fw-300">
            Online listings allow you to target specific buyers searching for properties in your area with your desired features and within your price range. This targeted approach ensures that those most likely to make an offer see your listing.  
          </div>
        </div>

        <div className="col-11 col-md-3 border-ss-primary p-4 text-white" data-aos="fade-up">
          <div className="fs-ss-24 pb-3 fw-300 text-ss-primary">
          Get an Accurate Market Valuation  
          </div>
          <div className="fs-ss-16 fw-300">
          Online property listings offer valuable insights into the market. By comparing your property to related listings and recent transactions, you can price it competitively, ensuring you get the best possible return on your investment.  
          </div>
        </div>

        <div className="col-11 col-md-3 border-ss-primary p-4 text-white" data-aos="fade-up">
          <div className="fs-ss-24 pb-3 fw-300 text-ss-primary">
          Stands Out the Competition    
          </div>
          <div className="fs-ss-16 fw-300">
          Listing your property online gives you a competitive advantage in Dubai's real estate market. By doing so, you'll stay ahead of the competition and increase your chances of attracting potential clients. Online listings can decide in a market where every advantage counts.  
          </div>
        </div>

        <div className="col-11 col-md-3 border-ss-primary p-4 text-white mx-4" data-aos="fade-up">
          <div className="fs-ss-24 pb-3 fw-300 text-ss-primary">
          Save Time with Online Listings    
          </div>
          <div className="fs-ss-16 fw-300">
          Reduce the hassle of managing multiple inquiries and viewings. With online listings, potential buyers can easily contact you, schedule viewings, or gather more information, streamlining the selling process.  
          </div>
        </div>

        <div className="col-11 col-md-3 border-ss-primary p-4 text-white" data-aos="fade-up">
          <div className="fs-ss-24 pb-3 fw-300 text-ss-primary">
          Data-Driven Insights to Improve Your Listing  
          </div>
          <div className="fs-ss-16 fw-300">
          Many online real estate portals offer analytics and performance feedback. These insights help you optimize your listing, track buyer interest, and make necessary adjustments to enhance your marketing strategy.   
          </div>
        </div>
        
      </div>
    </section>
  
     <section className="py-md-5 py-4" />

     <section className="container-fluid">
     <div className="fs-ss-30 pb-4 fw-500 text-center text-white pb-3" data-aos="fade-up">
     Inquiry Form  
      </div>
      <div className="row justify-content-center gy-4">
      <div className="col-11 col-md-6">
        <SellInquiryForm/>
      </div>
      </div>
      </section>

      <section className="py-md-5 py-4" />

    <Footer/>
    </section>
    </>
  );
}

export default SellProperty;
