import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Img1 from '../../assets/images/listing/listing4/1.webp';
import Img2 from '../../assets/images/listing/listing4/2.webp';
import Img3 from '../../assets/images/listing/listing4/3.webp';
import Img4 from '../../assets/images/listing/listing4/4.webp';
import Img5 from '../../assets/images/listing/listing4/5.webp';
import Img6 from '../../assets/images/listing/listing4/6.webp';
import Img7 from '../../assets/images/listing/listing4/7.webp';
import Img8 from '../../assets/images/listing/listing4/8.webp';
import Img9 from '../../assets/images/listing/listing4/9.webp';
import Img10 from '../../assets/images/listing/listing4/10.webp';
import Img11 from '../../assets/images/listing/listing4/11.webp';
import Img12 from '../../assets/images/listing/listing4/12.webp';
import Img13 from '../../assets/images/listing/listing4/13.webp';

import Header from "../../components/layout/Header";

import Footer from "../../components/layout/Footer";
import DetailsOfListing from "../../components/listing/individual/DetailsOfListing";
import InquiryOfListing from "../../components/listing/individual/InquiryOfListing";
import ShareSocialMediaIcons from "../../components/listing/individual/ShareSocialMediaIcons";
import { Link } from "react-router-dom";
import FeaturesAndAmenities from "../../components/listing/individual/FeaturesAndAmenities";
import ListingDetailsSection from "../../components/listing/individual/ListingDetailsSection";

const Listing4 = (props) => {
  
  const images = [
    {
      original: Img1,
      thumbnail: Img1,
    },
    {
      original: Img2,
      thumbnail: Img2,      
    },
    {
      original: Img3,
      thumbnail: Img3,      
    },
    {
      original: Img4,
      thumbnail: Img4,      
    },
    {
      original: Img5,
      thumbnail: Img5,      
    },
    {
      original: Img6,
      thumbnail: Img6,      
    },
    {
      original: Img7,
      thumbnail: Img7,      
    },
    {
      original: Img8,
      thumbnail: Img8,      
    },
    {
      original: Img9,
      thumbnail: Img9,      
    },
    {
      original: Img10,
      thumbnail: Img10,      
    },
    {
      original: Img11,
      thumbnail: Img11,      
    },
    {
      original: Img12,
      thumbnail: Img12,      
    },
    {
      original: Img13,
      thumbnail: Img13,      
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <Helmet>
      <title>
      Villa For Sale In Greenviews 2, Emaar South
      </title>
      <link rel="canonical" href="/properties-for-sale/dubai/villa-for-sale-in-greenviews-2-emaar-south"/>
    </Helmet>
    <Header/>
    <section id="website-responsive" className="container-fluid py-5 border-top border-1 border-white">
      <div className="row justify-content-evenly">
        <div className="col-11 col-md-7 px-0">
        <div className="">
          <div className="" data-aos="fade-up">
          <ImageGallery className="transition" items={images} />
          </div>
          <DetailsOfListing
          Title="VILLA FOR SALE IN GREENVIEWS 2, EMAAR SOUTH"
          Title2="Elegant Townhouses | 3BR Vila | Greenview 2"
          Price="2,199,995"
          Location="Dubai"
          Category="Villa"
          Beds="3 + Maid"
          Baths="4"
          Area="1,553 sqft / 144 sqm"
          FeaturesAndAmenities={<>
            <FeaturesAndAmenities Title="Maids Room" />
            <FeaturesAndAmenities Title="Central A/C" />
            <FeaturesAndAmenities Title="Balcony" />
            <FeaturesAndAmenities Title="Shared Pool" />
            <FeaturesAndAmenities Title="Shared Gym" />
            <FeaturesAndAmenities Title="Children's Pool" />
            <FeaturesAndAmenities Title="Children's Play Area" />
            <FeaturesAndAmenities Title="Barbecue Area" />  
            </>}
          Description={<>
          Villa for sale in Greenviews 2, EMAAR South
          <div className="py-2" />
          Located in Emaar South, Greenway 2 is adjacent to Expo City Dubai, the future vision of the Expo 2020 Dubai site. The community offers easy access to Al Maktoum International Airport and is well-connected to Dubai’s major attractions and business hubs via the adjacent boulevard and major highways.
          <div className="py-2" />
          Project Highlights:
          <div className="pt-1" />
          Exclusive Gated Community: Elegant townhouses and semi-detached homes <br />
          Design: Sophisticated 3- and 4-bedroom layouts <br />
          Amenities: Recreational facilities, top-tier schools, and lush green spaces <br />
          Location: Adjacent to Expo City Dubai, near Al Maktoum International Airport <br />
          Style: Contemporary design with modern facades and earth-toned interiors <br />
          Connectivity: Easy access to Dubai’s major attractions and business hubs
          <div className="py-2" />
          Discover Greenway 2 in Emaar South, where modern living meets natural beauty, and embrace a future filled with endless possibilities.
          </>}
          ListingDetailsSection={<>
          <ListingDetailsSection Title1="Property Type" Title2="Villa" />          
          <ListingDetailsSection Title1="Property Size" Title2="1,553 sqft / 144 sqm" />          
          <ListingDetailsSection Title1="Bedrooms" Title2="3 + Maid" />          
          <ListingDetailsSection Title1="Bathrooms" Title2="4" />     
          </>}
          />
      </div>
        </div>
        <div className="col-11 col-md-3">
          <div className="border-white border-1 border p-4 mb-4 sticky-top">
          <InquiryOfListing
          Title="Villa For Sale In Greenviews 2, Emaar South"
          />
          <ShareSocialMediaIcons
          url_Email="https://800homes.eacademe.org/villa-for-sale-dubai-dubai-south-dubai-world-central-emaar-south-greenviews-2-12319002"
          url_Whatsapp="https://800homes.eacademe.org/villa-for-sale-dubai-dubai-south-dubai-world-central-emaar-south-greenviews-2-12319002"
          url_Facebook="https://800homes.eacademe.org/villa-for-sale-dubai-dubai-south-dubai-world-central-emaar-south-greenviews-2-12319002"
          url_Linkedin="https://800homes.eacademe.org/villa-for-sale-dubai-dubai-south-dubai-world-central-emaar-south-greenviews-2-12319002"
          />
          </div>
        </div>        
      </div>
    </section>
    <Footer/>
    </>
  )
}

export default Listing4