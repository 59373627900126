import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Img1 from '../../assets/images/listing/listing10/1.webp';
import Img2 from '../../assets/images/listing/listing10/2.webp';
import Img3 from '../../assets/images/listing/listing10/3.webp';
import Img4 from '../../assets/images/listing/listing10/4.webp';
import Img5 from '../../assets/images/listing/listing10/5.webp';
import Img6 from '../../assets/images/listing/listing10/6.webp';
import Img7 from '../../assets/images/listing/listing10/7.webp';
import Img8 from '../../assets/images/listing/listing10/8.webp';
import Img9 from '../../assets/images/listing/listing10/9.webp';
import Img10 from '../../assets/images/listing/listing10/10.webp';
import Img11 from '../../assets/images/listing/listing10/11.webp';
import Img12 from '../../assets/images/listing/listing10/12.webp';
import Img13 from '../../assets/images/listing/listing10/13.webp';
import Img14 from '../../assets/images/listing/listing10/14.webp';
import Img15 from '../../assets/images/listing/listing10/15.webp';
import Img16 from '../../assets/images/listing/listing10/16.webp';
import Img17 from '../../assets/images/listing/listing10/17.webp';
import Img18 from '../../assets/images/listing/listing10/18.webp';
import Img19 from '../../assets/images/listing/listing10/19.webp';
import Img20 from '../../assets/images/listing/listing10/20.webp';
import Img21 from '../../assets/images/listing/listing10/21.webp';
import Img22 from '../../assets/images/listing/listing10/22.webp';
import Img23 from '../../assets/images/listing/listing10/23.webp';

import Header from "../../components/layout/Header";

import Footer from "../../components/layout/Footer";
import DetailsOfListing from "../../components/listing/individual/DetailsOfListing";
import InquiryOfListing from "../../components/listing/individual/InquiryOfListing";
import ShareSocialMediaIcons from "../../components/listing/individual/ShareSocialMediaIcons";
import { Link } from "react-router-dom";
import FeaturesAndAmenities from "../../components/listing/individual/FeaturesAndAmenities";
import ListingDetailsSection from "../../components/listing/individual/ListingDetailsSection";

const Listing10 = (props) => {
  
  const images = [
    {
      original: Img1,
      thumbnail: Img1,
    },
    {
      original: Img2,
      thumbnail: Img2,      
    },
    {
      original: Img3,
      thumbnail: Img3,      
    },
    {
      original: Img4,
      thumbnail: Img4,      
    },
    {
      original: Img5,
      thumbnail: Img5,      
    },
    {
      original: Img6,
      thumbnail: Img6,      
    },
    {
      original: Img7,
      thumbnail: Img7,      
    },
    {
      original: Img8,
      thumbnail: Img8,      
    },
    {
      original: Img9,
      thumbnail: Img9,      
    },
    {
      original: Img10,
      thumbnail: Img10,      
    },
    {
      original: Img11,
      thumbnail: Img11,      
    },
    {
      original: Img12,
      thumbnail: Img12,      
    },
    {
      original: Img13,
      thumbnail: Img13,      
    },
    {
      original: Img14,
      thumbnail: Img14,      
    },
    {
      original: Img15,
      thumbnail: Img15,      
    },
    {
      original: Img16,
      thumbnail: Img16,      
    },
    {
      original: Img17,
      thumbnail: Img17,      
    },
    {
      original: Img18,
      thumbnail: Img18,      
    },
    {
      original: Img19,
      thumbnail: Img19,      
    },
    {
      original: Img20,
      thumbnail: Img20,      
    },
    {
      original: Img21,
      thumbnail: Img21,      
    },
    {
      original: Img22,
      thumbnail: Img22,      
    },
    {
      original: Img23,
      thumbnail: Img23,      
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <Helmet>
      <title>
      Apartment For Sale In Vincitore Aqua Dimore, Dubai Science Park
      </title>
      <link rel="canonical" href="/properties-for-sale/dubai/apartment-for-sale-in-vincitore-aqua-dimore-dubai-science-park"/>
    </Helmet>
    <Header/>
    <section id="website-responsive" className="container-fluid py-5 border-top border-1 border-white">
      <div className="row justify-content-evenly">
        <div className="col-11 col-md-7 px-0">
        <div className="">
          <div className="" data-aos="fade-up">
          <ImageGallery className="transition" items={images} />
          </div>
          <DetailsOfListing
          Title="APARTMENT FOR SALE IN VINCITORE AQUA DIMORE, DUBAI SCIENCE PARK"
          Title2="Exquisite Luxury | Studio | Aqua Dimore"
          Price="789,000"
          Location="Dubai"
          Category="Apartment"
          Beds="Studio"
          Baths="1"
          Area="468 sqft / 43 sqm"
          FeaturesAndAmenities={<>
            <FeaturesAndAmenities Title="Study" />
            <FeaturesAndAmenities Title="Central A/C" />
            <FeaturesAndAmenities Title="Balcony" />
            <FeaturesAndAmenities Title="Shared Pool" />
            <FeaturesAndAmenities Title="Shared Spa" />
            <FeaturesAndAmenities Title="Security" />
            <FeaturesAndAmenities Title="Built in Wardrobes" />
            <FeaturesAndAmenities Title="Shared Gym" />
            <FeaturesAndAmenities Title="Children's Play Area" />
            <FeaturesAndAmenities Title="Barbecue Area" />  
            <FeaturesAndAmenities Title="Lobby in Building" />
            </>}
          Description={<>
          Apartment for sale in Vincitore Aqua Dimore, Dubai Science Park
          <div className="py-2" />
          Discover luxury living at Vincitore Aqua Dimore, an exquisite project by Vincitore Real Estate featuring stunning studios, 1 & 2-bedroom apartments, and opulent 2 & 3-bedroom aqua villas with designer private pools. This development promises a unique lifestyle in the vibrant Dubai Science Park.
          Situated in a thriving community, Aqua Dimore offers seamless connectivity to business hubs, shopping, and leisure venues. Designed for those who appreciate luxury, this project combines Elite Victorian craftsmanship with contemporary design, creating harmonious sanctuaries.
          <div className="py-2" />
          Enjoy Dubai's first indoor-outdoor sky pool, complemented by over 50,000 square feet of amenities, including a lazy river and cascading waterfalls. The architecture provides diverse living experiences—Aqua Living, SKY Living, and Nature Living—catering to all preferences.
          <div className="py-2" />
          With convenient retail spaces for daily essentials, Aqua Dimore is more than just a residence; it's a private oasis where tranquility meets luxury every day.
          <div className="py-2" />
          Key Highlights: <br />
          Luxury studios, 1 & 2-bedroom apartments, and aqua villal <br />
          Unique indoor-outdoor sky pool <br />
          Over 20 lifestyle amenities <br />
          Prime location with vibrant community access
          <div className="py-2" />
          Embrace the Aqua lifestyle today!

          </>}
          ListingDetailsSection={<>
          <ListingDetailsSection Title1="Property Type" Title2="Apartment" />          
          <ListingDetailsSection Title1="Property Size" Title2="468 sqft / 43 sqm" />          
          <ListingDetailsSection Title1="Bedrooms" Title2="Studio" />          
          <ListingDetailsSection Title1="Bathrooms" Title2="1" />     
          </>}
          />
      </div>
        </div>
        <div className="col-11 col-md-3">
          <div className="border-white border-1 border p-4 mb-4 sticky-top">
          <InquiryOfListing
          Title="Apartment For Sale In Vincitore Aqua Dimore, Dubai Science Park"
          />
          <ShareSocialMediaIcons
          url_Email="https://800homes.eacademe.org/apartment-for-sale-dubai-dubai-science-park-vincitore-aqua-dimore-12484484"
          url_Whatsapp="https://800homes.eacademe.org/apartment-for-sale-dubai-dubai-science-park-vincitore-aqua-dimore-12484484"
          url_Facebook="https://800homes.eacademe.org/apartment-for-sale-dubai-dubai-science-park-vincitore-aqua-dimore-12484484"
          url_Linkedin="https://800homes.eacademe.org/apartment-for-sale-dubai-dubai-science-park-vincitore-aqua-dimore-12484484"
          />
          </div>
        </div>        
      </div>
    </section>
    <Footer/>
    </>
  )
}

export default Listing10 