import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Img1 from '../../assets/images/listing/listing7/1.webp';
import Img2 from '../../assets/images/listing/listing7/2.webp';
import Img3 from '../../assets/images/listing/listing7/3.webp';
import Img4 from '../../assets/images/listing/listing7/4.webp';
import Img5 from '../../assets/images/listing/listing7/5.webp';
import Img6 from '../../assets/images/listing/listing7/6.webp';
import Img7 from '../../assets/images/listing/listing7/7.webp';
import Img8 from '../../assets/images/listing/listing7/8.webp';
import Img9 from '../../assets/images/listing/listing7/9.webp';
import Img10 from '../../assets/images/listing/listing7/10.webp';
import Img11 from '../../assets/images/listing/listing7/11.webp';
import Img12 from '../../assets/images/listing/listing7/12.webp';
import Img13 from '../../assets/images/listing/listing7/13.webp';
import Img14 from '../../assets/images/listing/listing7/14.webp';
import Img15 from '../../assets/images/listing/listing7/15.webp';
import Img16 from '../../assets/images/listing/listing7/16.webp';
import Img17 from '../../assets/images/listing/listing7/17.webp';
import Img18 from '../../assets/images/listing/listing7/18.webp';
import Img19 from '../../assets/images/listing/listing7/19.webp';
import Img20 from '../../assets/images/listing/listing7/20.webp';
import Img21 from '../../assets/images/listing/listing7/21.webp';
import Img22 from '../../assets/images/listing/listing7/22.webp';
import Img23 from '../../assets/images/listing/listing7/23.webp';
import Img24 from '../../assets/images/listing/listing7/24.webp';
import Img25 from '../../assets/images/listing/listing7/25.webp';
import Img26 from '../../assets/images/listing/listing7/26.webp';
import Img27 from '../../assets/images/listing/listing7/27.webp';
import Img28 from '../../assets/images/listing/listing7/28.webp';
import Img29 from '../../assets/images/listing/listing7/29.webp';
import Img30 from '../../assets/images/listing/listing7/30.webp';
import Img31 from '../../assets/images/listing/listing7/31.webp';
import Img32 from '../../assets/images/listing/listing7/32.webp';
import Img33 from '../../assets/images/listing/listing7/33.webp';
import Img34 from '../../assets/images/listing/listing7/34.webp';
import Img35 from '../../assets/images/listing/listing7/35.webp';

import Header from "../../components/layout/Header";

import Footer from "../../components/layout/Footer";
import DetailsOfListing from "../../components/listing/individual/DetailsOfListing";
import InquiryOfListing from "../../components/listing/individual/InquiryOfListing";
import ShareSocialMediaIcons from "../../components/listing/individual/ShareSocialMediaIcons";
import { Link } from "react-router-dom";
import FeaturesAndAmenities from "../../components/listing/individual/FeaturesAndAmenities";
import ListingDetailsSection from "../../components/listing/individual/ListingDetailsSection";

const Listing7 = (props) => {
  
  const images = [
    {
      original: Img1,
      thumbnail: Img1,
    },
    {
      original: Img2,
      thumbnail: Img2,      
    },
    {
      original: Img3,
      thumbnail: Img3,      
    },
    {
      original: Img4,
      thumbnail: Img4,      
    },
    {
      original: Img5,
      thumbnail: Img5,      
    },
    {
      original: Img6,
      thumbnail: Img6,      
    },
    {
      original: Img7,
      thumbnail: Img7,      
    },
    {
      original: Img8,
      thumbnail: Img8,      
    },
    {
      original: Img9,
      thumbnail: Img9,      
    },
    {
      original: Img10,
      thumbnail: Img10,      
    },
    {
      original: Img11,
      thumbnail: Img11,      
    },
    {
      original: Img12,
      thumbnail: Img12,      
    },
    {
      original: Img13,
      thumbnail: Img13,      
    },
    {
      original: Img14,
      thumbnail: Img14,      
    },
    {
      original: Img15,
      thumbnail: Img15,      
    },
    {
      original: Img16,
      thumbnail: Img16,      
    },
    {
      original: Img17,
      thumbnail: Img17,      
    },
    {
      original: Img18,
      thumbnail: Img18,      
    },
    {
      original: Img19,
      thumbnail: Img19,      
    },
    {
      original: Img20,
      thumbnail: Img20,      
    },
    {
      original: Img21,
      thumbnail: Img21,      
    },
    {
      original: Img22,
      thumbnail: Img22,      
    },
    {
      original: Img23,
      thumbnail: Img23,      
    },
    {
      original: Img24,
      thumbnail: Img24,      
    },
    {
      original: Img25,
      thumbnail: Img25,      
    },
    {
      original: Img26,
      thumbnail: Img26,      
    },
    {
      original: Img27,
      thumbnail: Img27,      
    },
    {
      original: Img28,
      thumbnail: Img28,      
    },
    {
      original: Img29,
      thumbnail: Img29,      
    },
    {
      original: Img30,
      thumbnail: Img30,      
    },
    {
      original: Img31,
      thumbnail: Img31,      
    },
    {
      original: Img32,
      thumbnail: Img32,      
    },
    {
      original: Img33,
      thumbnail: Img33,      
    },
    {
      original: Img34,
      thumbnail: Img34,      
    },
    {
      original: Img35,
      thumbnail: Img35,      
    }, 
  ];

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <Helmet>
      <title>
      Apartment For Sale In Primero Residences, Al Furjan
      </title>
      <link rel="canonical" href="/properties-for-sale/dubai/apartment-for-sale-in-primero-residences-al-furjan"/>
    </Helmet>
    <Header/>
    <section id="website-responsive" className="container-fluid py-5 border-top border-1 border-white">
      <div className="row justify-content-evenly">
        <div className="col-11 col-md-7 px-0">
        <div className="">
          <div className="" data-aos="fade-up">
          <ImageGallery className="transition" items={images} />
          </div>
          <DetailsOfListing
          Title="APARTMENT FOR SALE IN PRIMERO RESIDENCES, AL FURJAN"
          Title2="Luxury Meets Elegance | Primero 1BR"
          Price="980,000"
          Location="Dubai"
          Category="Apartment"
          Beds="1"
          Baths="2"
          Area="697 sqft / 65 sqm"
          FeaturesAndAmenities={<>
            <FeaturesAndAmenities Title="Study" />
            <FeaturesAndAmenities Title="Central A/C" />
            <FeaturesAndAmenities Title="Balcony" />
            <FeaturesAndAmenities Title="Shared Pool" />
            <FeaturesAndAmenities Title="Shared Spa" />
            <FeaturesAndAmenities Title="Security" />
            <FeaturesAndAmenities Title="Covered Parking" />
            <FeaturesAndAmenities Title="Built in Wardrobes" />
            <FeaturesAndAmenities Title="Kitchen Appliances" />
            <FeaturesAndAmenities Title="Shared Gym" />
            <FeaturesAndAmenities Title="Lobby in Building" />
            <FeaturesAndAmenities Title="Children's Pool" />
            <FeaturesAndAmenities Title="Children's Play Area" />
            <FeaturesAndAmenities Title="Barbecue Area" />  
            </>}
          Description={<>
          Apartment for sale in Primero Residences, Al Furjan
          <div className="py-2" />
          Discover the epitome of modern luxury with Primero Residences, an exceptional development inspired by the architectural grandeur and vibrant energy of Miami, USA. Nestled in one of Dubai’s most coveted locations, this architectural masterpiece redefines luxury living with its selection of one to three-bedroom split-level apartments.
          <div className="py-2" />
          Luxury Residences: Experience unparalleled elegance and sophistication in our beautifully designed units. Each apartment seamlessly blends contemporary design with timeless charm, offering a luxurious retreat in the heart of Al Furjan.
          <div className="py-2" />
          Prime Location: Strategically situated, Primero Residences ensures you’re never far from the best that Dubai has to offer. Enjoy effortless access to major attractions:
          <div className="py-2" />
          5 minutes to Ibn Battuta Mal <br />
          10 minutes to Discovery Gardens Metro Station <br />
          10 minutes to Blue Waters <br />
          10 minutes to Dubai Marina <br />
          15 minutes to Palm Jumeirah <br />
          10 minutes to Burj Al Arab <br />
          20 minutes to Mall of the Emirates
          <div className="py-2" />
          Exceptional Amenities: Embrace a lifestyle of convenience and connectivity. Primero Residences is ideally located near beaches, shopping centers, and boasts excellent transport links. The well-developed infrastructure includes nearby shops, schools, leisure, and sports facilities, all at competitive prices.
          <div className="py-2" />
          Elevate your living experience with Primero Residences—where the luxury of Miami meets the sophistication of Dubai.
          </>}
          ListingDetailsSection={<>
          <ListingDetailsSection Title1="Property Type" Title2="Apartment" />          
          <ListingDetailsSection Title1="Property Size" Title2="697 sqft / 65 sqm" />          
          <ListingDetailsSection Title1="Bedrooms" Title2="1" />          
          <ListingDetailsSection Title1="Bathrooms" Title2="2" />     
          </>}
          />
      </div>
        </div>
        <div className="col-11 col-md-3">
          <div className="border-white border-1 border p-4 mb-4 sticky-top">
          <InquiryOfListing
          Title="Apartment For Sale In Primero Residences, Al Furjan"
          />
          <ShareSocialMediaIcons
          url_Email="https://800homes.eacademe.org/apartment-for-sale-dubai-al-furjan-primero-residences-12387504"
          url_Whatsapp="https://800homes.eacademe.org/apartment-for-sale-dubai-al-furjan-primero-residences-12387504"
          url_Facebook="https://800homes.eacademe.org/apartment-for-sale-dubai-al-furjan-primero-residences-12387504"
          url_Linkedin="https://800homes.eacademe.org/apartment-for-sale-dubai-al-furjan-primero-residences-12387504"
          />
          </div>
        </div>        
      </div>
    </section>
    <Footer/>
    </>
  )
}

export default Listing7 