import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Img1 from '../../assets/images/listing/listing12/1.webp';
import Img2 from '../../assets/images/listing/listing12/2.webp';
import Img3 from '../../assets/images/listing/listing12/3.webp';
import Img4 from '../../assets/images/listing/listing12/4.webp';
import Img5 from '../../assets/images/listing/listing12/5.webp';
import Img6 from '../../assets/images/listing/listing12/6.webp';
import Img7 from '../../assets/images/listing/listing12/7.webp';
import Img8 from '../../assets/images/listing/listing12/8.webp';
import Img9 from '../../assets/images/listing/listing12/9.webp';
import Img10 from '../../assets/images/listing/listing12/10.webp';
import Img11 from '../../assets/images/listing/listing12/11.webp';
import Img12 from '../../assets/images/listing/listing12/12.webp';
import Img13 from '../../assets/images/listing/listing12/13.webp';
import Img14 from '../../assets/images/listing/listing12/14.webp';
import Img15 from '../../assets/images/listing/listing12/15.webp';
import Img16 from '../../assets/images/listing/listing12/16.webp';
import Img17 from '../../assets/images/listing/listing12/17.webp';
import Img18 from '../../assets/images/listing/listing12/18.webp';
import Img19 from '../../assets/images/listing/listing12/19.webp';
import Img20 from '../../assets/images/listing/listing12/20.webp';
import Img21 from '../../assets/images/listing/listing12/21.webp';
import Img22 from '../../assets/images/listing/listing12/22.webp';
import Img23 from '../../assets/images/listing/listing12/23.webp';

import Header from "../../components/layout/Header";

import Footer from "../../components/layout/Footer";
import DetailsOfListing from "../../components/listing/individual/DetailsOfListing";
import InquiryOfListing from "../../components/listing/individual/InquiryOfListing";
import ShareSocialMediaIcons from "../../components/listing/individual/ShareSocialMediaIcons";
import { Link } from "react-router-dom";
import FeaturesAndAmenities from "../../components/listing/individual/FeaturesAndAmenities";
import ListingDetailsSection from "../../components/listing/individual/ListingDetailsSection";

const Listing12 = (props) => {
  
  const images = [
    {
      original: Img1,
      thumbnail: Img1,
    },
    {
      original: Img2,
      thumbnail: Img2,      
    },
    {
      original: Img3,
      thumbnail: Img3,      
    },
    {
      original: Img4,
      thumbnail: Img4,      
    },
    {
      original: Img5,
      thumbnail: Img5,      
    },
    {
      original: Img6,
      thumbnail: Img6,      
    },
    {
      original: Img7,
      thumbnail: Img7,      
    },
    {
      original: Img8,
      thumbnail: Img8,      
    },
    {
      original: Img9,
      thumbnail: Img9,      
    },
    {
      original: Img10,
      thumbnail: Img10,      
    },
    {
      original: Img11,
      thumbnail: Img11,      
    },
    {
      original: Img12,
      thumbnail: Img12,      
    },
    {
      original: Img13,
      thumbnail: Img13,      
    },
    {
      original: Img14,
      thumbnail: Img14,      
    },
    {
      original: Img15,
      thumbnail: Img15,      
    },
    {
      original: Img16,
      thumbnail: Img16,      
    },
    {
      original: Img17,
      thumbnail: Img17,      
    },
    {
      original: Img18,
      thumbnail: Img18,      
    },
    {
      original: Img19,
      thumbnail: Img19,      
    },
    {
      original: Img20,
      thumbnail: Img20,      
    },
    {
      original: Img21,
      thumbnail: Img21,      
    },
    {
      original: Img22,
      thumbnail: Img22,      
    },
    {
      original: Img23,
      thumbnail: Img23,      
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <Helmet>
      <title>
      Apartment For Sale In Vincitore Aqua Flora, Dubai Science Park
      </title>
      <link rel="canonical" href="/properties-for-sale/dubai/apartment-for-sale-in-vincitore-aqua-flora-dubai-science-park"/>
    </Helmet>
    <Header/>
    <section id="website-responsive" className="container-fluid py-5 border-top border-1 border-white">
      <div className="row justify-content-evenly">
        <div className="col-11 col-md-7 px-0">
        <div className="">
          <div className="" data-aos="fade-up">
          <ImageGallery className="transition" items={images} />
          </div>
          <DetailsOfListing
          Title="APARTMENT FOR SALE IN VINCITORE AQUA FLORA, DUBAI SCIENCE PARK"
          Title2="Elegant Classic | Studio | Aqua Flora"
          Price="795,000"
          Location="Dubai"
          Category="Apartment"
          Beds="Studio"
          Baths="1"
          Area="457 sqft / 42 sqm"
          FeaturesAndAmenities={<>
            <FeaturesAndAmenities Title="Study" />
            <FeaturesAndAmenities Title="Central A/C" />
            <FeaturesAndAmenities Title="Balcony" />
            <FeaturesAndAmenities Title="Shared Pool" />
            <FeaturesAndAmenities Title="Shared Spa" />
            <FeaturesAndAmenities Title="Security" />
            <FeaturesAndAmenities Title="Built in Wardrobes" />
            <FeaturesAndAmenities Title="Shared Gym" />
            <FeaturesAndAmenities Title="Lobby in Building" />
            <FeaturesAndAmenities Title="Children's Play Area" />
            <FeaturesAndAmenities Title="Barbecue Area" />  
            </>}
          Description={<>
          Apartment for sale in Vincitore Aqua Flora, Dubai Science Park
          <div className="py-2" />
          Introducing Vincitore Aqua Flora, an upcoming residential marvel by Vincitore Development, poised to redefine luxury living in Dubai. This iconic landmark features premium apartments and villas, offering a unique blend of contemporary design and natural serenity. Residents are invited into a private oasis where opulence knows no limits.
          <div className="py-2" />
          Experience sophistication in every detail, from the stunning indoor-outdoor sky pool to the elegant classic Italian architecture. Aqua Flora boasts breathtaking views of the city skyline and tranquil waterfront, all while ensuring easy access to major business districts, shopping centers, and cultural hubs.
          <div className="py-2" />
          Enhancing the living experience, Aqua Flora includes an extensive array of amenities, such as a clubhouse, swimming pool, fitness centers, and meditation decks. This development goes beyond ordinary living, providing spaces that invite residents to escape, rejuvenate, and connect with themselves.
          Vincitore Aqua Flora is not just a home; it's a lifestyle that embraces luxury, comfort, and tranquility in the heart of Dubai.
          <div className="py-2" />
          Key Highlights: <br />
          Premium apartments and villas <br />
          Indoor-outdoor sky pool <br />
          Classic Italian architecture <br />
          Stunning city and waterfront views <br />
          Extensive amenities for relaxation and fitness
          <div className="py-2" />
          Elevate your living experience at Vincitore Aqua Flora!
          </>}
          ListingDetailsSection={<>
          <ListingDetailsSection Title1="Property Type" Title2="Apartment" />          
          <ListingDetailsSection Title1="Property Size" Title2="457 sqft / 42 sqm" />          
          <ListingDetailsSection Title1="Bedrooms" Title2="Studio" />          
          <ListingDetailsSection Title1="Bathrooms" Title2="1" />     
          </>}
          />
      </div>
        </div>
        <div className="col-11 col-md-3">
          <div className="border-white border-1 border p-4 mb-4 sticky-top">
          <InquiryOfListing
          Title="Apartment For Sale In Vincitore Aqua Flora, Dubai Science Park"
          />
          <ShareSocialMediaIcons
          url_Email="https://800homes.eacademe.org/apartment-for-sale-dubai-dubai-science-park-vincitore-aqua-flora-12484530"
          url_Whatsapp="https://800homes.eacademe.org/apartment-for-sale-dubai-dubai-science-park-vincitore-aqua-flora-12484530"
          url_Facebook="https://800homes.eacademe.org/apartment-for-sale-dubai-dubai-science-park-vincitore-aqua-flora-12484530"
          url_Linkedin="https://800homes.eacademe.org/apartment-for-sale-dubai-dubai-science-park-vincitore-aqua-flora-12484530"
          />
          </div>
        </div>        
      </div>
    </section>
    <Footer/>
    </>
  )
}

export default Listing12 