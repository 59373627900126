import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Img1 from '../../assets/images/listing/listing15/1.webp';
import Img2 from '../../assets/images/listing/listing15/2.webp';
import Img3 from '../../assets/images/listing/listing15/3.webp';
import Img4 from '../../assets/images/listing/listing15/4.webp';
import Img5 from '../../assets/images/listing/listing15/5.webp';
import Img6 from '../../assets/images/listing/listing15/6.webp';
import Img7 from '../../assets/images/listing/listing15/7.webp';
import Img8 from '../../assets/images/listing/listing15/8.webp';
import Img9 from '../../assets/images/listing/listing15/9.webp';
import Img10 from '../../assets/images/listing/listing15/10.webp';
import Img11 from '../../assets/images/listing/listing15/11.webp';
import Img12 from '../../assets/images/listing/listing15/12.webp';
import Img13 from '../../assets/images/listing/listing15/13.webp';
import Img14 from '../../assets/images/listing/listing15/14.webp';
import Img15 from '../../assets/images/listing/listing15/15.webp';
import Img16 from '../../assets/images/listing/listing15/16.webp';
import Img17 from '../../assets/images/listing/listing15/17.webp';
import Img18 from '../../assets/images/listing/listing15/18.webp';
import Img19 from '../../assets/images/listing/listing15/19.webp';
import Img20 from '../../assets/images/listing/listing15/20.webp';
import Img21 from '../../assets/images/listing/listing15/21.webp';
import Img22 from '../../assets/images/listing/listing15/22.webp';
import Img23 from '../../assets/images/listing/listing15/23.webp';
import Img24 from '../../assets/images/listing/listing15/24.webp';
import Img25 from '../../assets/images/listing/listing15/25.webp';
import Img26 from '../../assets/images/listing/listing15/26.webp';
import Img27 from '../../assets/images/listing/listing15/27.webp';
import Img28 from '../../assets/images/listing/listing15/28.webp';
import Img29 from '../../assets/images/listing/listing15/29.webp';

import Header from "../../components/layout/Header";

import Footer from "../../components/layout/Footer";
import DetailsOfListing from "../../components/listing/individual/DetailsOfListing";
import InquiryOfListing from "../../components/listing/individual/InquiryOfListing";
import ShareSocialMediaIcons from "../../components/listing/individual/ShareSocialMediaIcons";
import { Link } from "react-router-dom";
import FeaturesAndAmenities from "../../components/listing/individual/FeaturesAndAmenities";
import ListingDetailsSection from "../../components/listing/individual/ListingDetailsSection";

const Listing15 = (props) => {
  
  const images = [
    {
      original: Img1,
      thumbnail: Img1,
    },
    {
      original: Img2,
      thumbnail: Img2,      
    },
    {
      original: Img3,
      thumbnail: Img3,      
    },
    {
      original: Img4,
      thumbnail: Img4,      
    },
    {
      original: Img5,
      thumbnail: Img5,      
    },
    {
      original: Img6,
      thumbnail: Img6,      
    },
    {
      original: Img7,
      thumbnail: Img7,      
    },
    {
      original: Img8,
      thumbnail: Img8,      
    },
    {
      original: Img9,
      thumbnail: Img9,      
    },
    {
      original: Img10,
      thumbnail: Img10,      
    },
    {
      original: Img11,
      thumbnail: Img11,      
    },
    {
      original: Img12,
      thumbnail: Img12,      
    },
    {
      original: Img13,
      thumbnail: Img13,      
    },
    {
      original: Img14,
      thumbnail: Img14,      
    },
    {
      original: Img15,
      thumbnail: Img15,      
    },
    {
      original: Img16,
      thumbnail: Img16,      
    },
    {
      original: Img17,
      thumbnail: Img17,      
    },
    {
      original: Img18,
      thumbnail: Img18,      
    },
    {
      original: Img19,
      thumbnail: Img19,      
    },
    {
      original: Img20,
      thumbnail: Img20,      
    },
    {
      original: Img21,
      thumbnail: Img21,      
    },
    {
      original: Img22,
      thumbnail: Img22,      
    },
    {
      original: Img23,
      thumbnail: Img23,      
    },
    {
      original: Img24,
      thumbnail: Img24,      
    },
    {
      original: Img25,
      thumbnail: Img25,      
    },
    {
      original: Img26,
      thumbnail: Img26,      
    },
    {
      original: Img27,
      thumbnail: Img27,      
    },
    {
      original: Img28,
      thumbnail: Img28,      
    },
    {
      original: Img29,
      thumbnail: Img29,      
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <Helmet>
      <title>
      Apartment For Sale In Tower 3, Terhab Hotels & Tower
      </title>
      <link rel="canonical" href="/properties-for-sale/dubai/apartment-for-sale-in-tower-3-terhab-hotels-&-tower"/>
    </Helmet>
    <Header/>
    <section id="website-responsive" className="container-fluid py-5 border-top border-1 border-white">
      <div className="row justify-content-evenly">
        <div className="col-11 col-md-7 px-0">
        <div className="">
          <div className="" data-aos="fade-up">
          <ImageGallery className="transition" items={images} />
          </div>
          <DetailsOfListing
          Title="APARTMENT FOR SALE IN TOWER 3, TERHAB HOTELS & TOWERS"
          Title2="Lavish Unit | Studio - Terhab Hotel Tower"
          Price="789,736"
          Location="Dubai"
          Category="Apartment"
          Beds="2 + Maid"
          Baths="2"
          Area="458 sqft / 43 sqm"
          FeaturesAndAmenities={<>
            <FeaturesAndAmenities Title="Study" />
            <FeaturesAndAmenities Title="Central A/C" />
            <FeaturesAndAmenities Title="Balcony" />
            <FeaturesAndAmenities Title="Shared Pool" />
            <FeaturesAndAmenities Title="Shared Spa" />
            <FeaturesAndAmenities Title="Security" />
            <FeaturesAndAmenities Title="Built in Wardrobes" />
            <FeaturesAndAmenities Title="Kitchen Appliances" />
            <FeaturesAndAmenities Title="Shared Gym" />
            <FeaturesAndAmenities Title="Lobby in Building" />
            <FeaturesAndAmenities Title="Children's Play Area" />
            </>}
          Description={<>
          Experience luxury redefined in Dubai's vibrant Jumeirah Village Triangle (JVT) with the innovative Terhab Hotels & Towers. This stunning high-rise development features twin towers—24 and 56 storeys—offering a blend of lavish 4 and 5-star hotels alongside exquisite residential apartments, all designed to provide a clean and luxurious environment for families.
          <div className="py-2" />
          Enjoy hassle-free connectivity with easy access to major road networks, including Sheikh Zayed Road, Al Khail Road, and Burj Khalifa Street, making your travels seamless and convenient.
          <div className="py-2" />
          Terhab Hotels & Towers embraces a unique architectural design that harmonizes Arab tradition with modern luxury, creating an epicenter of charm and offering panoramic views of the dynamic cityscape.
          <div className="py-2" />
          Key Highlights: <br />
          State-of-the-art residential and hotel development <br />
          Twin high-rise towers for a stunning façade <br />
          Easy accessibility and mobility <br />
          World-class service and hospitality <br />
          Tranquil environment with posh amenities <br />
          Can stay 30 days a Year at the Novotel Hotel for Free
          <div className="py-2" />
          Elevate your lifestyle at Terhab Hotels & Towers—where luxury meets convenience!
          </>}
          ListingDetailsSection={<>
          <ListingDetailsSection Title1="Property Type" Title2="Apartment" />          
          <ListingDetailsSection Title1="Property Size" Title2="458 sqft / 43 sqm" />          
          <ListingDetailsSection Title1="Bedrooms" Title2="Studio" />          
          <ListingDetailsSection Title1="Bathrooms" Title2="1" />     
          </>}
          />
      </div>
        </div>
        <div className="col-11 col-md-3">
          <div className="border-white border-1 border p-4 mb-4 sticky-top">
          <InquiryOfListing
          Title="Apartment For Sale In Tower 3, Terhab Hotels & Tower"
          />
          <ShareSocialMediaIcons
          url_Email="https://800homes.eacademe.org/apartment-for-sale-dubai-jumeirah-village-triangle-terhab-hotels-towers-tower-3"
          url_Whatsapp="https://800homes.eacademe.org/apartment-for-sale-dubai-jumeirah-village-triangle-terhab-hotels-towers-tower-3"
          url_Facebook="https://800homes.eacademe.org/apartment-for-sale-dubai-jumeirah-village-triangle-terhab-hotels-towers-tower-3"
          url_Linkedin="https://800homes.eacademe.org/apartment-for-sale-dubai-jumeirah-village-triangle-terhab-hotels-towers-tower-3"
          />
          </div>
        </div>        
      </div>
    </section>
    <Footer/>
    </>
  )
}

export default Listing15 